<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */

"use strict";
import { onMounted, ref } from "vue";
import { useMainStore } from "../../../store/main/index";
import { useBuildingStore } from "@/store/building";
import { useRoute } from "vue-router";

import type { IndxInterface } from "~/store/main/types/StateType";

import BookingForm from "@/components/regularComponents/FormComponents/BookingForm.vue";
import BaseModal from "@/components/regularComponents/UIComponents/Modals/BaseModal.vue";
// import PhoneCall from "@/components/regularComponents/UIComponents/SVG/PhoneCall.vue";

const buildingStore = useBuildingStore();
const mainStore = useMainStore();
const route = useRoute();
const isModalOpen = ref(false);

const onClose = () => {
  isModalOpen.value = false;
};

// const handleButtonClick = () => {
//   isModalOpen.value = true;
// };

setTimeout(async () => {
  if (!buildingStore.projectList?.length) {
    await buildingStore.getProjects({ forceRefetch: true, params: { limit: 1000 } });
  }
}, 1000);

onMounted(() => {
  const dataState = useState<IndxInterface>("data");
  const timeout = Number(dataState.value.siteSettings?.popupTime);
  if (!timeout) return;

  setTimeout(() => {
    if (localStorage.getItem("ignore-popup")) return;
    isModalOpen.value = true;
  }, timeout);

  isModalOpen.value = route.query.hasOwnProperty(mainStore.autoOpenModalQueryKey);
});

// const showSelectedFlatsCount = computed(() => {
//   const hasItems = Object.keys(buildingStore.selectedFlatsForBooking || {})?.length;

//   return hasItems;
// });
</script>

<template>
  <!-- <aside class="fixed-call" :key="showSelectedFlatsCount">
    <div @click="handleButtonClick" class="fixed-call__left pos-fixed">
      <p class="uppercase" :lang="mainStore?.lang === 'ka' ? 'ka' : ''">
        {{ $t("greetings, if you have any questions, please feel free to contact us") }}
      </p>
      <div class="fixed-call__left-triangle pos-abs"></div>
    </div>
    <div class="fixed-call__img" @click="handleButtonClick">
      <PhoneCall />
    </div>

    <div
      class="fixed-call__count"
      v-if="showSelectedFlatsCount"
      :title="$t('selected flats')"
    >
      <p class="fixed-call__count-num">
        {{ buildingStore.selectedFlatsForBooking.length }}
      </p>
    </div>
  </aside> -->

  <Teleport to="body">
    <Transition name="fade" mode="out-in">
      <BaseModal class="booking-modal" v-if="isModalOpen" title="Contact Form" @close="onClose">
        <BookingForm @close="onClose" formType="requestCalls" eventFormType="Timed contact form" />
      </BaseModal>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
.fixed-call {
  &__left {
    display: flex;
    align-items: center;
    right: 185px;
    bottom: 120px;
    min-width: 90px;
    max-width: 300px;
    min-height: 56px;
    opacity: 1;
    padding: 10px;
    z-index: 9999;
    background: linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%);
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 1px 5px 8px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    @include maxq(desktop-2k) {
      right: 120px;
      bottom: 100px;
      min-width: 70px;
      max-width: 300px;
      min-height: 56px;
    }
    @include maxq(desktop-lg) {
      right: 96px;
      bottom: 85px;
      min-width: 45px;
      max-width: 199px;
      min-height: 56px;
    }
    &-triangle {
      content: "";
      position: absolute;
      z-index: 3;
      right: -14px;
      bottom: 22px;
      transform: rotate(-90deg);
      border: 8px solid;
      border-color: #f5f5f5 transparent transparent transparent;
    }
    p {
      font-family: Roboto;
      color: $black;
      font-size: 28px;
      line-height: 34px;
      overflow: hidden;
      word-break: break-word;
      max-height: 142px;
      @include clamp(3);
      @include maxq(desktop-lg) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    @include maxq(mobile) {
      display: none;
    }
  }
  @mixin animation($color: $mango) {
    animation: button-scale 1.5s linear 0.5s infinite;
    // animation-delay: 1.3s;
    &::before,
    &::after {
      content: "";
      background-color: $color;

      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      z-index: 0;
      top: 0;
      left: 0;
      z-index: -1;
      animation: button-wave infinite 2.6s 0s linear;
    }
    &::after {
      animation-delay: 1.3s;
    }
  }
  cursor: pointer;
  position: fixed;
  bottom: 90px;
  right: 40px;
  z-index: 12;
  @include easeOut(350ms, transform);

  @include maxq(desktop-2k) {
    right: 26px;
  }

  @include maxq(desktop-lg) {
    right: 17px;
  }

  @include maxq(mobile) {
    z-index: 11;
    // bottom: 18px;
    // right: 80px;
    bottom: 17px;
    right: 17px;
  }

  &__img {
    position: relative;
    border-radius: 50%;
    svg {
      --w: 120px;
      @include maxq(desktop-2k) {
        --w: 80px;
      }
      @include maxq(desktop-lg) {
        --w: 60px;
      }
      @include maxq(mobile) {
        --w: 50px;
      }
      display: flex;
      width: var(--w);
      height: var(--w);
      transform: scale(1.2);
    }

    @include animation;
  }

  &__count {
    position: absolute;
    top: -7px;
    right: -7px;
    z-index: 2;

    --w: 50px;
    --fsz: 2.5em;

    font-size: var(--fsz);

    @include maxq(desktop-2k) {
      --w: 40px;
      --fsz: 1.7em;
    }

    @include maxq(desktop-lg) {
      --w: 30px;
      --fsz: 1em;
    }

    @include maxq(mobile) {
      --w: 24px;
      --fsz: 0.9em;
      top: -5px;
      right: -5px;
    }

    &-num {
      background-color: #eb5757;
      color: $white;

      min-width: var(--w);
      min-height: var(--w);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      @include animation(#eb5757);
    }
  }
}

@keyframes button-wave {
  0% {
    transform: scale(1, 1);
    opacity: 0.45;
  }

  100% {
    transform: scale(1.35, 1.35);
    opacity: 0;
  }
}
@keyframes button-scale {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.05, 1.05);
  }
  100% {
    transform: scale(1.1, 1.1);
  }
}

html[lang="ka"] {
  .fixed-call__left p {
    font-family: NotoSansGeorgian;
  }
}
</style>
